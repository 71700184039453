<template>
  <span>{{ value.reference }} - {{ value.name }}</span>
</template>

<script>
export default {
  name: 'OfficePickerRenderComponent',
  props: ['value']
}
</script>

<style scoped>

</style>

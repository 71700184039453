<script>
import { cities } from '@/components/iam/SearchProviders'
import PickerMixin from '@/components/iam/PickerMixin'
import CityRenderer from '@/components/iam/CityPickerRenderComponent'

export default {
  name: 'CityPicker',

  mixins: [PickerMixin],
  props: {
    placeholder: {
      default: function () {
        return 'Selecteer een plaats'
      }
    },
    type: {
      default: function () {
        return 'cities'
      }
    }
  },
  data: function () {
    return {
      source: cities.ttAdapter(),
      suggestionRenderComponent: function (value) {
        return '<div><strong>' + value.zip_code + '</strong> ' + value.name + '</div>'
      },
      selectionRenderComponent: CityRenderer
    }
  }
}
</script>

<style scoped>

</style>

<script>
import { contactGroups } from '@/components/iam/SearchProviders'
import MultiplePickerMixin from '@/components/iam/MultiplePickerMixin'
import ContactGroupRenderer from '@/components/iam/ContactGroupPickerRenderComponent'

export default {
  name: 'MultipleContactGroupPicker',

  mixins: [MultiplePickerMixin],
  props: {
    placeholder: {
      default () {
        return 'Selecteer een groep'
      }
    },
    selectionRenderComponent: {
      default () {
        return ContactGroupRenderer
      }
    },
    type: {
      default () {
        return 'contactGroups'
      }
    }
  },
  data () {
    return {
      source: contactGroups.ttAdapter(),
      suggestionRenderComponent (value) {
        return `<div><strong>${value.name}</strong></div>`
      }
    }
  }
}
</script>

<style scoped>

</style>

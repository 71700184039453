<template>
  <span> {{ value.zip_code }} {{ value.name }}</span>
</template>

<script>
export default {
  name: 'CityPickerRenderComponent',
  props: ['value']
}
</script>

<style scoped>

</style>

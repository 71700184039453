<template>
  <Modal :title="title" ref="modal">
    <span v-if="config.segmented">
      <span :key="'segment-' + segment.id" v-for="segment in config.segments">
        <h2 v-html="segment.title" v-if="segment.title"></h2>
        <label v-html="segment.subtitle" v-if="segment.subtitle"></label>

        <div v-if="segment.tabbed">
          <div class="form-horizontal">
            <div class="form-group">
              <label class="control-label col-md-4">{{ segment.label }}</label>
              <div class="tabs-container col-sm-8" style="padding-bottom: 15px;">
                <ul class="nav nav-tabs">
                  <li :class="{ 'active': index === 0 }" :key="'tab-title-' + tab.title"
                      v-for="(tab, index) in segment.tabs">
                    <a :href="'#tab-' + segment.id + '-' + tab.title" aria-expanded="true" data-toggle="tab">
                      {{ tab.title }}
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div :class="{ 'active': idx === 0 }" :id="'tab-' + segment.id + '-' + tab.title"
                       :key="'tab-' + tab.title"
                       class="tab-pane" v-for="(tab, idx) in segment.tabs">
                    <div class="panel-body">
                      <EditModalField :field="field" :key="index" @invalid="handleInvalidField"
                                      @valid="handleValidField" v-for="(field, index) in tab.fields"
                                      v-model="values[field.value]"></EditModalField>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'indent': segment.subtitle}" v-else>
          <EditModalField :class="field.class" :field="field" :key="field.value" @invalid="handleInvalidField"
                          @valid="handleValidField" v-for="field in segment.fields"
                          v-model="values[field.value]"></EditModalField>
        </div>
      </span>
    </span>
    <span v-else>
      <div>
        <EditModalField :field="field" :key="index" @invalid="handleInvalidField"
                        @valid="handleValidField" v-for="(field, index) in config.fields"
                        v-model="values[field.value]"></EditModalField>
      </div>
    </span>

    <span slot="footer">
        <button :disabled="!isValid" @click="save" class="btn btn-primary">Opslaan</button>
        <a @click="hide" class="btn btn-white">Annuleren</a>
      </span>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'
import EditModalField from '../iam/EditModalField'
import Vue from 'vue'
import $ from 'jquery'

export default {
  name: 'EditModal',
  props: ['config', 'object'],
  components: { Modal, EditModalField },
  data () {
    return {
      values: {},
      invalidFields: []
    }
  },
  watch: {
    config (val) {
      this.values = {}
      this.invalidFields = []
      if (val.segmented) {
        val.segments.forEach((segment) => {
          if (segment.tabbed) {
            segment.tabs.forEach((tab) => {
              tab.fields.forEach((field) => {
                if (field.value) Vue.set(this.values, field.value, this._getObjectValue(this.object, field.value))
              })
            })
          } else {
            segment.fields.forEach((field) => {
              if (field.value) Vue.set(this.values, field.value, this._getObjectValue(this.object, field.value))
            })
          }
        })
      } else {
        val.fields.forEach((field) => {
          if (field.value) Vue.set(this.values, field.value, this._getObjectValue(this.object, field.value))
        })
      }
    },
    object (val) {
      this.values = {}
      this.invalidFields = []
      if (this.config.segmented) {
        this.config.segments.forEach((segment) => {
          if (segment.tabbed) {
            segment.tabs.forEach((tab) => {
              tab.fields.forEach((field) => {
                if (field.value) {
                  const objectValue = this._getObjectValue(val, field.value)
                  Vue.set(this.values, field.value, objectValue)
                }
              })
            })
          } else {
            segment.fields.forEach((field) => {
              if (field.value) {
                const objectValue = this._getObjectValue(val, field.value)
                Vue.set(this.values, field.value, objectValue)
              }
            })
          }
        })
      } else {
        if (this.config.fields) {
          this.config.fields.forEach((field) => {
            if (field.value) {
              const objectValue = this._getObjectValue(val, field.value)
              Vue.set(this.values, field.value, objectValue)
            }
          })
        }
      }
    }
  },
  methods: {
    clear () {
      this.values = {}
      this.invalidFields = []
    },
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    save () {
      if (this.values.city) this.values.city = this.values.city.id

      // fix unchanged checkboxes when used for creation
      if (this.config.segmented) {
        this.config.segments.forEach((segment) => {
          if (segment.tabbed) {
            segment.tabs.forEach((tab) => this._fixUnchangedCheckboxes(tab.fields))
          } else {
            this._fixUnchangedCheckboxes(segment.fields)
          }
        })
      } else {
        this._fixUnchangedCheckboxes(this.config.fields)
      }

      // resolve layered keys (example: type.id)
      const keys = Object.keys(this.values)
      keys.forEach((key) => {
        const parentKey = key.split('.')[0]
        if (parentKey !== key) {
          if (this._shouldObjectify(key, keys)) {
            const childKey = key.split('.')[1]
            // key parent appears more than once so default logic will keep overwriting it
            if (!this.values[parentKey]) {
              // parent Does not exist yet so create it
              this.values[parentKey] = {}
            } else {
              // parent exists so destringify it
              this.values[parentKey] = JSON.parse(this.values[parentKey])
            }
            // add child and stringify
            this.values[parentKey][childKey] = this.values[key]
            this.values[parentKey] = JSON.stringify(this.values[parentKey])
          } else {
            this.values[parentKey] = this.values[key]
          }
          delete this.values[key]
        }
      })
      this.$emit('save', this.values)
    },
    _stringifyObjects (values) {
      const keys = Object.keys(values)
      keys.forEach((key) => {
        if (values[key] !== undefined && !Array.isArray(values[key]) && values[key].stringify !== undefined) {
          if (typeof values[key] === 'object' && values[key] !== null && values[key].stringify) {
            values[key] = JSON.stringify(values[key])
          } else {
            if (typeof values[key] === 'object' && values[key] !== null) {
              values[key] = JSON.stringify(values[key])
            }
          }
        }
      })
      return values
    },
    _setDeep (obj, path, value, setrecursively = false) {
      let level = 0

      path.reduce((a, b) => {
        level++

        if (setrecursively && typeof a[b] === 'undefined' && level !== path.length) {
          a[b] = {}
          return a[b]
        }
        if (level === path.length) {
          a[b] = value
          return value
        } else {
          return a[b]
        }
      }, obj)
    },
    _shouldObjectify (key, keys) {
      const levels = key.split('.').length
      let result = false
      keys.forEach((k) => {
        if (k.split('.').length === levels && k.split('.')[0] === key.split('.')[0] && k !== key) result = true
      })
      return result
    },
    _fixUnchangedCheckboxes (fields) {
      fields.forEach((field) => {
        if (field.type === 'checkbox') {
          if (this.values[field.value] === undefined) {
            this.values[field.value] = false
          }
        }
      })
    },
    handleInvalidField (field) {
      const index = this.invalidFields.indexOf(field)
      if (index === -1) this.invalidFields.push(field)
    },
    handleValidField (field) {
      this.invalidFields = this.invalidFields.filter(e => e !== field)
    },
    _getObjectValue (object, value) {
      let fieldType = null
      if (this.config.segmented) {
        this.config.segments.forEach((segment) => {
          if (segment.tabbed) {
            segment.tabs.forEach((tab) => {
              tab.fields.forEach((field) => {
                if (field.value === value) fieldType = field.type
              })
            })
          } else {
            segment.fields.forEach((field) => {
              if (field.value === value) fieldType = field.type
            })
          }
        })
      } else {
        this.config.fields.forEach((field) => {
          if (field.value === value) fieldType = field.type
        })
      }

      const values = value.split('.')
      let result = object
      values.forEach((val) => {
        try {
          result = result[val]
        } catch (e) {
          result = {}
        }
      })

      // Make sure empty numbers are empty and not empty vue objects
      const isEmptyNumberObject = fieldType === 'number' && $.isEmptyObject(result) && typeof result === 'object'
      result = isEmptyNumberObject ? null : result
      return result
    }
  },
  computed: {
    title () {
      return this.config.title ? this.config.title : 'Bewerken'
    },
    isValid () {
      return this.invalidFields.length === 0
    }
  }
}
</script>

<style scoped>
  .indent {
    margin-left: 30px;
  }

  .control-label {
    text-align: left;
  }
</style>

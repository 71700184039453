<script>
import { offices } from '@/components/iam/SearchProviders'
import MultiplePickerMixin from '@/components/iam/MultiplePickerMixin'
import OfficeRenderer from '@/components/iam/OfficePickerRenderComponent'

export default {
  name: 'MultipleOfficePicker',

  mixins: [MultiplePickerMixin],
  props: {
    placeholder: {
      default () {
        return 'Selecteer een kantoor'
      }
    },
    type: {
      default () {
        return 'offices'
      }
    },
    multiple: {
      default () {
        return false
      }
    }
  },
  data () {
    return {
      source: offices.ttAdapter(),
      suggestionRenderComponent: (value) => {
        return `<div>
                    <strong>${value.reference} - ${value.name}</strong><br>
                    <i class="fa fa-envelope"></i>${(value.email || '<em>geen email</em>')}<br>
                  </div>`
      },
      selectionRenderComponent: OfficeRenderer
    }
  }
}
</script>

<style scoped>

</style>

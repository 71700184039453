<script>
import { contactGroups } from '@/components/iam/SearchProviders'
import PickerMixin from '@/components/iam/PickerMixin'
import ContactGroupRenderer from '@/components/iam/ContactGroupPickerRenderComponent'

export default {
  name: 'ContactGroupPicker',

  mixins: [PickerMixin],
  props: {
    placeholder: {
      default: function () {
        return 'Selecteer een groep'
      }
    },
    selectionRenderComponent: {
      default: function () {
        return ContactGroupRenderer
      }
    },
    type: {
      default: function () {
        return 'contacts'
      }
    }
  },
  data () {
    return {
      source: contactGroups.ttAdapter(),
      suggestionRenderComponent: function (value) {
        return `<div><strong>${value.name}</strong></div>`
      }

    }
  }
}
</script>

<style scoped>

</style>

<script>
import { offices } from '@/components/iam/SearchProviders'
import PickerMixin from '@/components/iam/PickerMixin'
import OfficeRenderer from '@/components/iam/OfficePickerRenderComponent'

export default {
  name: 'OfficePicker',

  mixins: [PickerMixin],
  props: {
    placeholder: {
      default () {
        return 'Selecteer een kantoor'
      }
    },
    type: {
      default () {
        return 'offices'
      }
    },
    multiple: {
      default () {
        return false
      }
    }
  },
  data () {
    return {
      source: offices.ttAdapter(),
      suggestionRenderComponent: (value) => {
        return `<div>
                    <strong>${value.reference} - ${value.name}</strong><br>
                    <i class="fa fa-envelope"></i> ${(value.email || '<em>geen email</em>')}<br>
                  </div>`
      },
      selectionRenderComponent: OfficeRenderer
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div class="input-group date">
    <datePicker v-model="date" :config="config"></datePicker>
    <span class="input-group-addon">
    <span class="glyphicon glyphicon-calendar"></span>
    </span>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
  name: 'DatePicker',
  props: ['value'],
  components: { datePicker },
  data () {
    return {
      date: this.value,
      config: {
        format: 'YYYY-MM-DD',
        locale: 'nl-be'
      }
    }
  },
  watch: {
    value () {
      this.date = this.value
    },
    date () {
      this.$emit('input', this.date)
    }
  }
}
</script>

<style scoped>
</style>
<style src="../../../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css"></style>

import { render, staticRenderFns } from "./ContactGroupPickerRenderComponent.vue?vue&type=template&id=7b677c42&scoped=true&"
import script from "./ContactGroupPickerRenderComponent.vue?vue&type=script&lang=js&"
export * from "./ContactGroupPickerRenderComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b677c42",
  null
  
)

export default component.exports
µ
<template>
  <div class="input-group date">
    <datePicker :config="config" @dp-change="updatePicker" v-model="date"></datePicker>
    <span class="input-group-addon">
    <span class="glyphicon glyphicon-calendar"></span>
    </span>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
  name: 'DateTimePicker',
  props: {
    value: {
      default: '',
      required: true
    },
    config: {
      type: Object,
      default: function () {
        return {
          format: 'YYYY-MM-DD HH:mm',
          defaultDate: this.value,
          locale: 'nl-be'
        }
      }
    }
  },
  components: { datePicker },
  data () {
    return {
      date: new Date()
    }
  },
  watch: {
    value () {
      this.date = this.value
    }
  },
  methods: {
    updatePicker () {
      this.$emit('input', this.date)
    }
  }
}
</script>

<style scoped>
</style>
<style src="../../../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css"></style>

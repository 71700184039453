<template>
  <span>{{ value.display_name}}</span>
</template>

<script>
export default {
  name: 'CollaboratorPickerRenderComponent',
  props: ['value']
}
</script>

<style scoped>

</style>

<template>
  <form class="form-horizontal">
    <div class="form-group" v-if="visible">
      <h3 v-if="field.type === 'header'">{{ field.label }}</h3>
      <template v-else>
        <label v-if="showLabel" v-html="field.label" class="control-label col-sm-4" />
        <div :class="showLabel ? 'col-md-8' : 'col-md-12'">
          <input v-if="field.type === 'text'" v-model="fieldValue" :placeholder="field.placeholder" class="form-control" type="text" />
          <textarea v-if="field.type === 'textarea'" v-model="fieldValue" :placeholder="field.placeholder" class="form-control" />
          <NumberField ref="numField" v-if="field.type === 'number'" v-model="fieldValue" :validators="field.validators" />

          <div class="col-sm-12" style="padding-left: 0;" v-if="field.type === 'city'">
            <CityPicker v-model="fieldValue" class="col-sm-6" style="padding-left: 0;" />
          </div>

          <div v-if="field.type === 'contact' && !field.multiple" class="col-sm-12" style="padding-left: 0;">
            <ContactPicker ref="ContactPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'contact' && field.multiple" class="col-sm-12" style="padding-left: 0;">
            <MultipleContactPicker ref="MultipleContactPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'contact_group' && !field.multiple" class="col-sm-12" style="padding-left: 0;">
            <ContactGroupPicker ref="ContactGroupPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'contact_group' && field.multiple" class="col-sm-12" style="padding-left: 0;">
            <MultipleContactGroupPicker ref="MultipleContactGroupPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'collaborator' && !field.multiple" class="col-sm-12" style="padding-left: 0;">
            <CollaboratorPicker ref="CollaboratorPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'collaborator' && field.multiple" class="col-sm-12" style="padding-left: 0;">
            <MultipleCollaboratorPicker ref="MultipleCollaboratorPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'office' && !field.multiple" class="col-sm-12" style="padding-left: 0;">
            <OfficePicker ref="OfficePicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'office' && field.multiple" class="col-sm-12" style="padding-left: 0;">
            <MultipleOfficePicker ref="MultipleOfficePicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'property' && !field.multiple" class="col-sm-12" style="padding-left: 0;">
            <PropertyPicker ref="PropertyPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'project' && !field.multiple" class="col-sm-12" style="padding-left: 0;">
            <ProjectPicker ref="ProjectPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'property' && field.multiple" class="col-sm-12" style="padding-left: 0;">
            <MultiplePropertyPicker ref="MultiplePropertyPicker" v-model="fieldValue" />
          </div>

          <div v-if="field.type === 'dropdown'" class="col-sm-12" style="padding-left: 0;">
            <Dropdown ref="Dropdown" v-model="fieldValue" :options=field.options />
          </div>

          <select v-if="field.type === 'select'" v-model="fieldValue" class="form-control">
            <option
              v-for="option in parseOptions(field.options)"
              :key="option.value"
              :value="option.value"
              v-html="option.name"
            />
          </select>

          <select class="form-control" v-if="field.type === 'yes_no_not_entered'" v-model="fieldValue">
            <option value="1">Ja</option>
            <option value="2">Nee</option>
            <option value="3">Niet ingegeven</option>
          </select>

          <select class="form-control" v-if="field.type === 'yes_no_unknown'" v-model="fieldValue">
            <option value="0">Onbekend</option>
            <option value="1">Ja</option>
            <option value="2">Nee</option>
          </select>

          <select class="form-control" v-if="field.type === 'yes_no_request'" v-model="fieldValue">
            <option value="1">Ja</option>
            <option value="2">Nee</option>
            <option value="3">Niet ingegeven</option>
            <option value="4">In aanvraag</option>
          </select>

          <select v-if="field.type === 'yes_no_both'" v-model="fieldValue" class="form-control">
            <option value="1">Ja</option>
            <option value="2">Nee</option>
            <option value="5">Beide mogelijk</option>
          </select>

          <label v-if="field.type === 'checkbox'">
            <input
              type="checkbox"
              style="margin-top: 10px;"
              :disabled="field.disabled"
              v-model="fieldValue"
            >
          </label>

          <input v-if="field.type === 'upload'" ref="uploadField" type="file" @change="handleUploadFieldChange" />
          <RadioField v-if="field.type === 'radio'" v-model="fieldValue" :options="field.options" />
          <PicturePicker v-if="field.type === 'picture'"  v-model="fieldValue" :pictures="field.options" :return-value="field.return_value" />
          <TranslatableField v-if="field.type === 'translatable'" v-model="fieldValue" :label="field.label" />
          <DatePicker v-if="field.type === 'date'" v-model="fieldValue" />
          <DateTimePicker v-if="field.type === 'datetime'" v-model="fieldValue" />
        </div>
      </template>
    </div>
  </form>

</template>

<script>
import Vue from 'vue'
import CityPicker from '../properties/CityPicker'
import PropertyPicker from '../properties/PropertyPicker'
import ProjectPicker from '../properties/ProjectPicker'
import MultiplePropertyPicker from '../properties/MultiplePropertyPicker'
import ContactPicker from '@/components/contacts/ContactPicker'
import MultipleContactPicker from '@/components/contacts/MultipleContactPicker'
import ContactGroupPicker from '@/components/contacts/ContactGroupPicker'
import MultipleContactGroupPicker from '@/components/contacts/MultipleContactGroupPicker'
import CollaboratorPicker from '@/components/organization/CollaboratorPicker'
import MultipleCollaboratorPicker from '@/components/organization/MultipleCollaboratorPicker'
import OfficePicker from '@/components/organization/OfficePicker'
import MultipleOfficePicker from '@/components/organization/MultipleOfficePicker'
import EventBus from '@/components/iam/bus'
import PicturePicker from '../iam/PicturePicker'
import Dropdown from '../iam/Dropdown'
import TranslatableField from '../iam/TranslatableField'
import DatePicker from '../iam/DatePicker'
import DateTimePicker from '../iam/DateTimePicker'

Vue.component('RadioField', {
  props: ['value', 'options'],
  template: `
      <div class="btn-group col-md-12" data-toggle="buttons">
        <label class="radio-inline" v-for="option in options" :key="option.value" :for="\'r-\' + option.name">
          <input type="radio" :value="option.value" v-model="val" :id="\'r-\' + option.name"> {{ option.name }}
        </label>
      </div>`,
  data () {
    return { val: this.value }
  },
  watch: {
    val (v) {
      this.$emit('input', v)
    }
  }
})

Vue.component('NumberField', {
  props: ['value', 'validators'],
  template: `<input
      type="text"
      class="form-control"
      v-model="content"
      :min="min"
      @input="update"
      @blur="toIntegerIfAllLeadingZeroes"
    >`,
  data () {
    return {
      content: this.value
    }
  },
  watch: {
    value () {
      this.content = this.value
    }
  },
  computed: {
    min () {
      if (this.validators.indexOf('positive') > -1) {
        return 0
      } else {
        return ''
      }
    }
  },
  methods: {
    /**
       * For the night is dark and full of terrors... but our code is lacking errors :-)
       * It's ugly but it works, please forgive us...
       * TL;DR If we can't parse to float, set the input to 0 (numeric zero). Yes, you can't enter negative numbers,
       * we decided we don't need it (at this time).
       * Arne, Yannick, Michael
       *
       * 20/12/2019:
       * Black magic has been replaced by blood magic, Yannick added regex...
       *
       * 24/07/2019 15:27:
       * This hack no longer bites us in the ass (for now) by making it even worse :-)
       *
       * 24/07/2019 14:43:
       * This hack bites us in the ass
       */
    update () {
      let content = this.content
      const integer = content.match(/^[0-9]+$/g)
      const decimalWithDotNonLeadingZero = content.match(/^[0-9]+\.[1-9]+$/g)
      const decimalWithCommaNonLeadingZero = content.match(/^[0-9]+,[1-9]+$/g)
      const decimalWithDotSomeLeadingZero = content.match(/^[0-9]+\.0+[1-9]+$/g)
      const decimalWithCommaSomeLeadingZero = content.match(/^[0-9]+,0+[1-9]+$/g)
      const decimalWithCommaAllLeadingZeroes = content.match(/^[0-9]+,0+$/g)

      if (integer) {
        content = parseInt(content)
      } else if (decimalWithDotNonLeadingZero || decimalWithDotSomeLeadingZero) {
        content = parseFloat(content)
      } else if (decimalWithCommaNonLeadingZero || decimalWithCommaSomeLeadingZero) {
        content = parseFloat(content.replace(',', '.'))
      } else if (decimalWithCommaAllLeadingZeroes) {
        content = content.replace(',', '.')
      }

      if (content === '') {
        content = 0
      }

      this.$emit('input', content)
    },
    toIntegerIfAllLeadingZeroes () {
      const content = toString(this.content)
      const decimalWithDotAllLeadingZeroes = content.match(/^[0-9]+\.0+$/g)
      const decimalWithCommaAllLeadingZeroes = content.match(/^[0-9]+,0+$/g)
      if (decimalWithCommaAllLeadingZeroes || decimalWithDotAllLeadingZeroes) {
        this.content = parseInt(content)
      }
    }
  }
})

export default {
  name: 'EditModalField',
  props: ['value', 'field'],
  components: {
    CityPicker,
    PropertyPicker,
    ProjectPicker,
    MultiplePropertyPicker,
    ContactPicker,
    MultipleContactPicker,
    ContactGroupPicker,
    MultipleContactGroupPicker,
    PicturePicker,
    CollaboratorPicker,
    MultipleCollaboratorPicker,
    OfficePicker,
    MultipleOfficePicker,
    Dropdown,
    TranslatableField,
    DatePicker,
    DateTimePicker
  },
  data () {
    return {
      fieldValue: null,
      visible: true,
      valid: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (!val) this.clear()
        this.fieldValue = val
      }
    },
    fieldValue (val) {
      this.$emit('input', val)
      this._doValidityChecks(val)
      if (this.field.visibility_slave) {
        if (this.field.visibility_slave.constructor === Array) {
          this.field.visibility_slave.forEach((slave) => {
            this.doVisibilityChange(slave, this.field.reverse_visibility ? !val : val)
          })
        } else {
          this.doVisibilityChange(this.field.visibility_slave, this.field.reverse_visibility ? !val : val)
        }
      }
    },
    valid (val) {
      if (val) {
        this.$emit('valid', this.field.value)
      } else {
        this.$emit('invalid', this.field.value)
      }
    }
  },
  computed: {
    showLabel () {
      return this.field.type !== 'translatable' && this.field.label
    }
  },
  methods: {
    parseOptions (options) {
      if (options.nameKey && options.valueKey) {
        const nameKey = options.nameKey
        const valueKey = options.valueKey
        const opts = {}
        options.options.forEach((opt) => {
          const value = opt[nameKey]
          const key = opt[valueKey]
          opts[key] = value
        })
        return this._formatOptions(opts)
      } else {
        return this._formatOptions(options)
      }
    },
    handleVisibilityChange (data) {
      if (this.field.visibility_master && data.field === this.field.value) this.visible = data.visible
    },
    handleVisibilityCheck (field) {
      if (field === this.field.value) {
        if (this.field.visibility_slave.constructor === Array) {
          this.field.visibility_slave.forEach((slave) => {
            this.doVisibilityChange(slave, this.field.reverse_visibility ? !this.fieldValue : this.fieldValue)
          })
        } else {
          this.doVisibilityChange(this.field.visibility_slave, this.field.reverse_visibility ? !this.fieldValue : this.fieldValue)
        }
      }
    },
    doVisibilityChange (field, value) {
      if (value === undefined) {
        value = this.field.reverse_visibility ? this.field.reverse_visibility : false
      }
      EventBus.$emit('edit_modal_field_visibility_change', { field: field, visible: value })
    },

    handleUploadFieldChange () {
      this.fieldValue = this.$refs.uploadField.files[0]
    },

    _formatOptions (options) {
      const opts = []
      if (options instanceof Map) {
        options.forEach((v, k) => {
          opts.push({ value: k, name: v })
        })
      } else {
        Object.keys(options).forEach((key) => opts.push({ value: key, name: options[key] }))
      }
      return opts
    },
    _doValidityChecks (val) {
      // required check
      let valid = !(this.field.required && (val === null || val === '' || val === {} || val === undefined))
      // valid number check for number field
      if (this.field.type === 'number') {
        if (valid && val && isNaN(val)) valid = false
      }
      // check validators
      if (this.field.validators) {
        this.field.validators.forEach((validator) => {
          // positive validator: Value must be 0 or higher
          if (validator === 'positive' && ['number'].indexOf(this.field.type) > -1) {
            if (valid && val < 0) valid = false
          }
        })
      }
      this.valid = valid
    },
    clear () {
      if (this.$refs.ContactPicker) this.$refs.ContactPicker.clear()
      if (this.$refs.MultipleContactPicker) this.$refs.MultipleContactPicker.clear()
      if (this.$refs.CollaboratorPicker) this.$refs.CollaboratorPicker.clear()
      if (this.$refs.MultipleCollaboratorPicker) this.$refs.MultipleCollaboratorPicker.clear()
      if (this.$refs.OfficePicker) this.$refs.OfficePicker.clear()
      if (this.$refs.MultipleOfficePicker) this.$refs.MultipleOfficePicker.clear()
    }
  },
  created () {
    EventBus.$on('edit_modal_field_visibility_change', this.handleVisibilityChange)
    EventBus.$on('edit_modal_field_visibility_check', this.handleVisibilityCheck)
    if (this.field.visibility_slave) {
      if (this.field.visibility_slave.constructor === Array) {
        this.field.visibility_slave.forEach((slave) => {
          this.doVisibilityChange(slave, this.field.reverse_visibility ? !this.fieldValue : this.fieldValue)
        })
      } else {
        this.doVisibilityChange(this.field.visibility_slave, this.field.reverse_visibility ? !this.fieldValue : this.fieldValue)
      }
    }
    if (this.field.visibility_master) EventBus.$emit('edit_modal_field_visibility_check', this.field.visibility_master)
    this._doValidityChecks(this.fieldValue)
    if ('visible' in this.field) this.visible = this.field.visible
  }
}
</script>

<style scoped>
  .control-label {
    text-align: left;
  }
</style>

<template>
  <div class="btn-group">
    <span class="form-control"><span>{{selectedValue.name}}</span> <span class="caret" data-toggle="dropdown"></span></span>
<!--    <button :class="{ 'btn-secondary': disabled, 'btn-primary': !disabled}"-->
<!--            class="btn dropdown-toggle" ref="dropdownButton" data-toggle="dropdown">-->
<!--      {{buttonName}} <span class="badge">{{itemsSelected}}</span> <span class="caret"></span>-->
<!--    </button>-->

    <!--main group-->
    <ul class="dropdown-menu">
      <span :key="option.name" v-for="option in options">
        <li class="dropdown-header" @click.stop="toggleGroup(option.name)" ref="dropdownHeader">
          {{option.name}}
          <i class="fa fa-chevron-down" v-if="visibleGroups.indexOf(option.name) > -1"></i>
          <i class="fa fa-chevron-left" v-else></i>
        </li>

        <!--subgroup-->
        <ul class="subgroup" v-if="option.subGroups" v-show="visibleGroups.indexOf(option.name) > -1">
          <li class="subgroup-li" :key="subgroup.id"
              v-for="subgroup in option.subGroups"
          >
            <span class="dropdown-item dropdown-item-sub"
                  :data-id="subgroup.id"
                  :data-type="option.name"
                  @click.stop="selectItem"
            >
              {{subgroup.name}}
              <i class="fa fa-check" v-show="dropdownValues.indexOf(subgroup.id) > -1"></i>
            </span>

            <!-- subsubgroup -->
            <ul class="subsubgroup" v-if="subgroup.subGroups">
              <li class="subgroup-li" :key="subsubgroup.id"
                  v-for="subsubgroup in subgroup.subGroups"
              >
                  <span class="dropdown-item dropdown-item-sub-sub"
                        :data-id="subsubgroup.id"
                        :data-type="option.name"
                        @click.stop="selectItem">
                    {{subsubgroup.name}}
                    <i class="fa fa-check" v-show="dropdownValues.indexOf(subsubgroup.id) > -1"></i>
                  </span>

                <!-- subsubsubgroup -->
              <ul class="subsubsubgroup" v-if="subsubgroup.subGroups">
                <li class="subgroup-li" :key="subsubsubgroup.id"
                    v-for="subsubsubgroup in subsubgroup.subGroups"
                >
                    <span class="dropdown-item dropdown-item-sub-sub-sub"
                          :data-id="subsubsubgroup.id"
                          :data-type="option.name"
                          @click.stop="selectItem">
                      {{subsubsubgroup.name}}
                      <i class="fa fa-check" v-show="dropdownValues.indexOf(subsubsubgroup.id) > -1"></i>
                    </span>
                </li>
              </ul>
              </li>
            </ul>
          </li>
        </ul>
      </span>
    </ul>

  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Dropdown',
  props: {
    value: {},
    options: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      dropdownValues: [],
      dropdownTypes: [],
      dropdownOpen: false,
      disabled: false,
      selectedValue: { name: 'Haunted Mansion', id: 27 },
      visibleGroups: []
    }
  },
  mounted () {
    const firstDropdownHeader = this.$refs.dropdownHeader[0]
    firstDropdownHeader.click()
    this.processTypes()
  },
  methods: {
    toggleGroup (group) {
      const index = this.visibleGroups.indexOf(group)
      if (index > -1) {
        this.visibleGroups.splice(index, 1)
      } else {
        this.visibleGroups.push(group)
      }
    },
    clickSubGroupItems (elem) {
      const mainNodeCheckHidden = !$(elem).children('i').first().is(':hidden')
      if (elem.nextSibling) {
        elem.nextSibling.nextSibling.childNodes.forEach((node) => {
          const subNodeCheckHidden = $(node).children('span').children().first().is(':hidden')
          if ((mainNodeCheckHidden && !subNodeCheckHidden) || (!mainNodeCheckHidden && subNodeCheckHidden)) {
            node.firstChild.click()
          }
        })
      }
    },
    // Adds and emits selected items
    selectItem (event) {
      const item = parseInt(event.target.dataset.id)
      if (this.dropdownValues.indexOf(item) > -1) {
        this.dropdownValues = this.dropdownValues.filter(i => i !== item)
        const removalIndex = this.dropdownTypes.indexOf(event.target.dataset.type)
        if (removalIndex > -1) {
          this.dropdownTypes.splice(removalIndex, 1)
          if (!this.dropdownTypes.some((dropDownType) => dropDownType === event.target.dataset.type)) {
            this.$emit('dropdownGroupEmpty', event.target.dataset.type)
          }
        }
      } else {
        this.dropdownValues.push(item)
        this.dropdownTypes.push(event.target.dataset.type)
        this.$emit('dropdownGroupAdded', event.target.dataset.type)
      }
      this.$emit('updatedDropdownValues', this.dropdownValues)
      this.clickSubGroupItems(event.target)
      return false
    },
    disable () {
      this.disabled = true
    },
    enable () {
      this.disabled = false
    },
    processTypes () {
      /**
         * Find a selected type in the group structure
         *  => Recursive Fun!!
         */
      function findInGroup (id, group) {
        const foundGroups = []

        if (group.id && id === group.id) {
          foundGroups.push(group)
        } else {
          if (group.subGroups) {
            group.subGroups.forEach(g => {
              const x = findInGroup(id, g)
              if (x.length > 0) {
                foundGroups.push(x)
              }
            })
          }
        }
        return foundGroups
      }

      this.dropdownTypes = []
      this.types.forEach(type => {
        this.groups.forEach(group => {
          if (findInGroup(type, group).length > 0) this.dropdownTypes.push(group.name)
        })
      })
      this.dropdownTypes.forEach(value => this.$emit('dropdownGroupAdded', value))
    }
  },
  watch: {
    types () {
      this.processTypes()
      this.dropdownValues = this.types.slice()
    }
  },
  computed: {
    itemsSelected () {
      const groupArrays = Object.values(this.dropdownValues)
      return groupArrays.flat().length
    }
  }
}
</script>

<style scoped>
  ul {
    padding-left: 0px;
    margin-left: 0px;
  }

  .dropdown-menu {
    width: 250px;
  }

  .dropdown-header {
    font-size: 16px;
    padding-left: 5px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    margin-bottom: 10px;
    font-weight: bold;
    padding-right:0px;
  }

  .subgroup-li {
    cursor: pointer;
    list-style-type: none;
    font-size: 14px;
  }

  .dropdown-item {
    display: inline-block;
  }

  .dropdown-item:hover {
    background-color: #22a647;
    color: whitesmoke;
  }

  .dropdown-item:hover > .fa-check {
    color: whitesmoke;
  }

  .dropdown-item-sub {
    width: 250px;
    position: relative;
    padding-left: 5px;

  }

  .dropdown-item-sub-sub {
    width: 250px;
    padding-left: 15px;
  }

  .dropdown-item-sub-sub-sub {
    width: 250px;
    padding-left: 25px;
  }

  .fa-check {
    color: #22a647;
    margin: 3px 5px 0px 0px;
    float: right
  }
  .fa-chevron-left, .fa-chevron-down{
    float:right;
    padding: 4px 5px 0px 0px;
  }
</style>

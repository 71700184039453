<script>
import { collaborators } from '@/components/iam/SearchProviders'
import MultiplePickerMixin from '@/components/iam/MultiplePickerMixin'
import CollaboratorRenderer from '@/components/iam/CollaboratorPickerRenderComponent'

export default {
  name: 'MultipleCollaboratorPicker',

  mixins: [MultiplePickerMixin],
  props: {
    placeholder: {
      default () {
        return 'Selecteer een medewerker'
      }
    },
    type: {
      default () {
        return 'collaborators'
      }
    },
    multiple: {
      default () {
        return false
      }
    }
  },
  data () {
    return {
      source: collaborators.ttAdapter(),
      suggestionRenderComponent: (value) => {
        return `<div><strong>${value.display_name}</strong><br>
                    <i class="fa fa-envelope"></i>${(value.email || '<em>geen email</em>')}<br>
                  </div>`
      },
      selectionRenderComponent: CollaboratorRenderer
    }
  }
}
</script>

<style scoped>

</style>
